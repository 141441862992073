<template>
  <div id="EmpreendimentoEspelhoVenda">
    <v-toolbar-title :style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
      <!-- TITULO DA PAGINA -->
      <v-toolbar-title
        class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
          :to="{ name: 'Empreendimentos' }"
          class="nav-link"
          aria-current="page">
          <v-btn
            icon
            dark
            color="grey"
            class="btn-icon mr-1">
            <v-icon
              color="#84A0CD"
              class="icon-action">
              mdi-chevron-left
              </v-icon>
          </v-btn>
        </router-link>
        Espelho de Vendas
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->
  
      <div class="d-flex d-sm-none mt-n14 pt-5">
        <v-sheet class="cabecalho d-flex pa-2" style="width: 100%;">
          <v-avatar size="80px" 
            v-ripple  
            class="grey lighten-2">
            <v-img 
              :src="store_Empreendimento.empreendimento_edicao.empreend_logo" 
              height="100%">
            </v-img>
          </v-avatar>
          <v-col class="pa-0 pl-2">
            <div>
              <span
                class="header-text mr-4">
                <span
                  class="text-subtitle-2"
                  :style="{ 'color':COR_SUBTITULO }">
                    {{ store_Empreendimento.empreendimento_edicao.empreend_nome }}
                </span>
              </span>
              
              <span
                class="d-block d-sm-inline header-text text-subtitle-2">
                Cidade: 
                <span 
                  :style="{ 'color':COR_SUBTITULO }">
                  {{ store_Empreendimento.empreendimento_edicao?.empreend_municipio?.nome }} - 
                  {{ store_Empreendimento.empreendimento_edicao?.empreend_municipio?.uf }}
                </span>
              </span>
            </div>
          </v-col>
        </v-sheet>
      </div>
    </v-toolbar-title>
  
    <v-container class="d-flex flex-wrap container rounded-lg mt-n10 pa-0">
      <v-card v-if="!isMobile"  elevation="0" class="card d-flex flex-column pb-0">
        <v-card-text class="card-content my-0 py-0 px-2">
          <v-row class="d-none d-sm-block wrapper-top rounded-tl-lg my-0 py-0 px-1">
            <!-- COLUNA TOPO ------------------------------->
            <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet class="cabecalho d-flex  mx-auto pa-4 pb-2">
                <v-avatar size="80px" 
                  v-ripple  
                  class="grey lighten-2">
                  <v-img 
                    :src="store_Empreendimento.empreendimento_edicao.empreend_logo" 
                    height="100%">
                  </v-img>
                </v-avatar>
                <v-col class="pa-0 pl-1">
                  <div>
                    <span class="header-text body-2 font-weight-medium mr-4">Código: <span class="font-weight-bold body-1" :style="{ 'color':COR_SUBTITULO }">{{ store_Empreendimento.empreendimento_edicao.cod_empreendimento }}</span></span>
                    <span class="header-text body-2 font-weight-medium">Data: <span class="" :style="{ 'color':COR_SUBTITULO }">{{ formatDate(store_Empreendimento.empreendimento_edicao.empreend_matr_data) }}</span></span>
                  </div>
  
                  <div>
                    <span
                      class="header-text mr-4">
                      Empreendimento: 
                      <span
                        class=""
                        :style="{ 'color':COR_SUBTITULO }">
                        {{ store_Empreendimento.empreendimento_edicao.empreend_nome }}
                      </span>
                    </span>
                    <span
                      class="d-block d-sm-inline header-text mr-4">
                      Situação: 
                      <span
                        :style="{ 'color':COR_SUBTITULO }">
                        {{ store_Empreendimento.empreendimento_edicao.empreend_situacao }}
                      </span>
                    </span>
                    <span class="d-block d-sm-inline header-text">Área total: <span class="" :style="{ 'color':COR_SUBTITULO }">{{ store_Empreendimento.empreendimento_edicao.empreend_area }}</span></span>
                  </div>
                  <div v-if="!!store_Empreendimento.empreendimento_edicao.empreend_empresa">
                    <span class="header-text mr-4">Administradora: <span class="" :style="{ 'color':COR_SUBTITULO }">{{ store_Empreendimento.empreendimento_edicao.empreend_empresa.nome }}</span></span>
                  </div>
                   <div v-else>
                    <span class="header-text mr-4">Administradora: <span class="" :style="{ 'color':COR_SUBTITULO }">{{  }}</span></span>
                  </div>
                  
                </v-col>
              </v-sheet>
            </v-col>
            <v-col>
              <div class="titulo_propostas d-flex">
                <div>
                  <v-text-field
                    dense
                    v-model="search"
                    cache-items
                    label="Quadra / Lote"
                    placeholder="Quadra / Lote"
                    outlined
                    filled
                    background-color="#FFF"
                    clearable
                    tabindex="0"
                    class="mr-2">
                      </v-text-field>
                </div>
                <div class="d-flex" style="align-items: baseline;">
                  <div class="text-center d-flex mr-4">
                    <v-btn
                      fab
                      elevation="0"
                      height="35"
                      width="35"
                      class="mr-2"
                      @click="all">
                      <v-icon
                        :color="COR_SUBTITULO">
                        mdi-arrow-expand-vertical
                      </v-icon>
                    </v-btn>
                    <v-btn
                      elevation="0"
                      fab
                      height="35"
                      width="35"
                     @click="none">
                      <v-icon
                        :color="COR_SUBTITULO">
                        mdi-arrow-collapse-vertical
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-switch
                    class="pa-0 mt-0 mb-0"
                    v-model="switch1"
                    label="Disponíveis"
                    :color="COR_SUBTITULO"
                    inset>
                  </v-switch>
                </div>
              </div>
        
              <div class="d-flex mt-n4 " >
                <v-card 
                  style="width: 100%;"
                  :style="{ 'background-color':'transparent' }"
                  elevation="0">
                  <v-expansion-panels
                    v-model="panel"
                    multiple
                    >
                    <v-expansion-panel
                      v-for="(items,i) in itensFiltro"
                      :key="i">
                      <v-expansion-panel-header>
                        Quadra:&nbsp;<strong style="font-size: 16px">{{ items[0].quadra }}</strong>
                        <v-badge
                            :content="items.length"
                            color="blue accent-1"
                            offset-x="30"
                            offset-y="10" >
                          </v-badge>
                          <template v-slot:actions>
                          <v-icon :color="COR_SUBTITULO">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>                            
                        <span
                          class="d-flex"
                          style="display: flex; flex-wrap: wrap;">
                          <div
                            v-for="(item, ii) in items"
                            :key="ii"
                            class="mx-1 mt-n3">
                            <v-avatar
                              @click="handleOpenDialog(item)"
                              style="display: flex; flex-wrap: wrap; cursor: pointer; border-radius: 10px;"
                              class="ma-5"
                              :color="item.cor"
                              size="80">
                              <div>
                                <span
                                style="color: white; font-weight: bold; font-size: 20px!important;">
                                {{ item.lote }}
                                </span>
                                <br>
                                <span
                                  style="color: white; font-weight: bold; font-size: 12px!important;">
                                  {{ formatNumber(Number(item.metragem)) }}&sup2;
                                </span>
                                <span
                                  v-if="item.situacao_sistema == 'DISPONIVEL' || item.situacao_sistema == 'RESERVADO'"
                                  style="white-space: nowrap; color: white; font-weight: bold; font-size: 11px!important;">
                                  $ {{ formatNumber(Number(item.valor)) }}
                                </span>
                              </div>
                            </v-avatar>
                          </div>
                        </span>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
                

              </div>
            </v-col>
            <!-- FIM COLUNA TOPO ------------------------------->
          </v-row>
        </v-card-text>
      </v-card>


      <template v-if="isMobile">
        <v-row class="mt-0 text-body-2">
          <v-col>
              <div class="titulo_propostas ml-1">
                <div>
                  <v-text-field
                    v-model="search"
                    dense
                    style="width:137px"
                    cache-items
                    label="Quadra / Lote"
                    placeholder="Quadra / Lote"
                    outlined
                    filled
                    background-color="#FFF"
                    clearable
                    tabindex="0">
                  </v-text-field>
                </div>

                <div class="d-flex justify-center mt-n3 ml-n2" style="align-items: baseline;">
                  <v-switch
                    id="mySwitch"
                    class="pa-0 mt-0 mb-0 switch mr-2"
                    v-model="switch1"
                    label-class="body-2"
                    :color="COR_SUBTITULO"
                    inset>
                    <template v-slot:label><span class="text-body-2">Disponiveís</span></template>
                  </v-switch>
                  <div class="text-center d-flex mr-3">
                    <v-btn
                      fab
                      height="35"
                      width="35"
                      class="mr-2 elevation-0"
                      @click="all">
                      <v-icon
                        :color="COR_SUBTITULO">
                        mdi-arrow-expand-vertical
                      </v-icon>
                    </v-btn>
                    <!-- <div>{{ panel }}</div> -->
                    <v-btn
                      fab
                      class="elevation-0"
                      height="35"
                      width="35"
                      @click="none">
                      <v-icon
                        :color="COR_SUBTITULO">
                        mdi-arrow-collapse-vertical
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
        
              <div class="d-flex mt-n4 " >
                <v-card 
                  style="width: 100%;"
                  :style="{ 'background-color':'transparent' }"
                  elevation="0"
                  class="mx-1 pa-n2">
                  <v-expansion-panels
                    v-model="panel"
                    multiple
                    >
                    <v-expansion-panel
                      v-for="(items,i) in itensFiltro"
                      :key="i">
                      <v-expansion-panel-header class="mx-0">
                        Quadra:&nbsp;<strong style="font-size: 16px">{{ items[0].quadra }}</strong>
                        <v-badge
                          :content="items.length"
                          color="blue accent-1"
                          offset-x="30"
                          offset-y="10" >
                        </v-badge>
                        <template v-slot:actions>
                          <v-icon :color="COR_SUBTITULO">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                            
                        <span
                          class="d-flex"
                          style="display: flex; flex-wrap: wrap;">
                          <div
                            v-for="(item, ii) in items"
                            :key="ii"
                            class="mx-n5 mt-n3">
                            <v-avatar
                              @click="handleOpenDialog(item)"
                              style="display: flex; flex-wrap: wrap; cursor: pointer; border-radius: 10px;"
                              class="ma-8"
                              :color="item.cor"
                              size="80">
                              <div>
                                <span
                                style="color: white; font-weight: bold; font-size: 20px!important;">
                                {{ item.lote }}
                                </span>
                                <br>
                                <span
                                  style="color: white; font-weight: bold; font-size: 12px!important;">
                                  {{ formatNumber(Number(item.metragem)) }}&sup2;
                                </span>
                                <span
                                  v-if="item.situacao_sistema == 'DISPONIVEL' || item.situacao_sistema == 'RESERVADO'"
                                  style="white-space: nowrap; color: white; font-weight: bold; font-size: 11px!important;">
                                  $ {{ formatNumber(Number(item.valor)) }}
                                </span>
                              </div>
                            </v-avatar>
                          </div>
                        </span>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </div>
            </v-col>
        </v-row>
      </template>
     
    </v-container>

    <!-- NOVO MODAL USANDO VUETIFY -->
    <v-container justify="center">
      <v-dialog
        v-model="store_ModalNegociacao.dialog"
        max-width="440"
        content-class="custom-dialog"
        ref="modalnegociacao"
        :retain-focus="false"
        height="300px"
        persistent
      >
        <v-card class="justify-end align-center">
          <ModalNegociacao ref="ModalNegociacao" />
        </v-card>
      </v-dialog>
    </v-container>
    <!-- NOVO MODAL USANDO VUETIFY -->

  </div>
</template>

<script>
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO
} from "../../services/constantes";
import store_site from "../../store/store_site"
import store_Empreendimento from "./store_Empreendimento";
import store_Empreendimentos from "./store_Empreendimentos"
import {
  formatDateTime,
  formatDate,
  formatNumber,
  groupArrayMultKeys
} from "../../services/funcoes";
import { API, BASE_URL_WEBSOCKET } from "../../services/API"
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import { OpcaoVendaGet } from "../../services/negociacoes";
import ModalNegociacao from "../ModalNegociacao/ModalNegociacao.vue"
import io from 'socket.io-client';
import GeoJSON from "ol/format/GeoJSON";

var socket;

export default {

  name : "EmpreendimentoEspelhoVenda",

  components: {
    ModalNegociacao
  },

  data() {
    return {
      store_site              : store_site,
      store_Empreendimento    : store_Empreendimento,
      store_Empreendimentos   : store_Empreendimentos,
      store_ModalNegociacao   : store_ModalNegociacao,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SUBTITULO: COR_SUBTITULO,
      COR_SECUNDARIA: COR_SECUNDARIA,

      formatDateTime  : formatDateTime,
      formatDate      : formatDate,
      formatNumber    : formatNumber,

      geo_JSON        : null,

      switch1 : true,
      search  : "",

      expand: true,
      tab: null,
      items: 5,
      dados : null,
      panel : [],
    }
  },
  
  async created() {
    this.id = this.$route.params.cod_empreendimento;

    // Buscando Empreendimento Selecionado
    //console.log('CREATED:::', this.$route.params.cod_empreendimento);
    await this.busca_Empreendimento(
      { params: { cod_empreendimento: this.$route.params.cod_empreendimento } },
      "SELECIONADO"
    );
  },

  async mounted() {
    var __this = this;
    socket = io(BASE_URL_WEBSOCKET, {  transports          : ['websocket'],
                                            secure              : false,
                                            reconnection        : true,
                                            rejectUnauthorized  : false,
                                            forceNew            : true
                                        }
                        );
    socket.on("connected", function(e) {
      // console.log("ON CONNECTED: ", __this.store_site.cod_empresa, __this.id);
      socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
    });
    socket.on("connect", function(e) {
      // console.log("ON CONNECT: ", __this.store_site.cod_empresa, __this.id);
      socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
    });
    socket.on("connection", function(e) {
      // console.log("ON CONNECTION: ", __this.store_site.cod_empresa, __this.id);
      socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
    });
    socket.on('reconnect', function(e) {
      // console.log("ON RECONNECT: ", __this.store_site.cod_empresa, __this.id);
      socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
    })

    // Callback de resposta de atualizações do Mapa -----
    socket.on("mapa", function(data)  {
      //console.log("mapa atualizou:---------------- ");
      if (JSON.parse(data).mapa) {
        __this.atualizaPoligonos(JSON.parse(data).mapa);
      }
    });

    this.MapaByEmpreendimento(this.$route.params.cod_empreendimento)
    var lo_params = {
      cod_empreendimento: this.$route.params.cod_empreendimento
    };
    if (lo_params.cod_empreendimento) {
      const lo_Res = await this.store_Empreendimento.EmpreendimentoGet(lo_params);

      if (!!lo_Res) {
        this.store_Empreendimento.empreendimento_edicao = { ...lo_Res };
      }
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    itensFiltro() {
      var valores = []
      let new_array = []
      if (!!this.dados) {
        new_array =  Object.values(this.dados) 
        const filter = this.search ? this.search.toLowerCase() : "";
        valores =  new_array.filter(item => {
          if (this.switch1) {
            return item.situacao_sistema.toUpperCase() === "DISPONIVEL" && (item.quadra.toLowerCase().includes(filter) || item.lote.toLowerCase().includes(filter));
          }
          else{
            return item.quadra.toLowerCase().includes(filter) || item.lote.toLowerCase().includes(filter);
          }
        });
      }

      const array_agrupado = groupArrayMultKeys(valores,['quadra_ordem']) ?? []

      // essa função foi criada para ordenar o array e exibir de forma correta em ordem crescente
      const ordenado = Object.keys(array_agrupado).sort().reduce((acumulador, chave) => {
          acumulador[chave] = array_agrupado[chave];
          return acumulador;
        }, {});
      return ordenado
    }
  },

  methods: {
    async busca_Empreendimento(p_params = {}, p_result) {
      try {

        const response = await API.get("empreendimento_com_filtro", p_params);
        //console.log("empreendimento_com_filtro", response)
        if (response) {
          if (response.data.result.data == "Não possui dados") {
            if (p_result == "SIMILARES") this.resultSimilares = [];
            if (p_result == "SELECIONADO")
              this.store_Empreendimentos.empreend_selecionado = {};
          } else {
            if (p_result == "SIMILARES") {
              this.resultSimilares = response.data.result.data;
              //console.log("this.resultSimilares", this.resultSimilares)
            }
            if (p_result == "SELECIONADO") {
              // Tentado pegar a Quadra/lote aoós ser montada no MapaLotes
              var quadras = [];
              var lotes = [];

              if (this.store_Empreendimentos.empreend_selecionado.quadras)
                quadras = this.store_Empreendimentos.empreend_selecionado.quadras;
              if (this.store_Empreendimentos.empreend_selecionado.lotes)
                lotes = this.store_Empreendimentos.empreend_selecionado.lotes;
              var lo_Aux = response.data.result;
              lo_Aux.quadras = quadras;
              lo_Aux.lotes = lotes;
              this.store_Empreendimentos.empreend_selecionado = lo_Aux;
              this.store_Empreendimentos.fotos_galeria = lo_Aux.empreend_imagem;
            }
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    },

    handleOpenDialog(unidade) {
      //console.log(unidade)
      this.abre_imovel(unidade.cod_imovel);
    },

    async abre_imovel(p_cod_imovel) {
      try {
        this.store_ModalNegociacao.dados = {};

        // Buscando botoes habilitados deste imovel -------------------------
        this.store_ModalNegociacao.botoes = [];
        this.store_ModalNegociacao.botoes = await OpcaoVendaGet(p_cod_imovel);
        if (this.store_ModalNegociacao.botoes == "Não possui dados") {
          this.store_ModalNegociacao.botoes = [];
        }
        else {
          // Buscando Imovel -----------------------------------------
          const resp = await API.get("/imovel", {
            params: {
              cod_empresa : this.store_site.cod_empresa,
              cod_imovel  : p_cod_imovel,
            },
          });
          const { data } = resp;
          if (data.message === "warning" || data.message === "error") {
            //console.log("data.message", data);
          }
          else {
            //console.log('BUSCA IMOVEL -------- ',  data.result[0])
            this.store_ModalNegociacao.step = "ModalNegociar";
            this.store_ModalNegociacao.dados = data.result[0];
            this.store_ModalNegociacao.dados.preco_final    = this.store_ModalNegociacao.dados.preco_total;
            this.store_ModalNegociacao.dados.preco_desconto = 0;
            this.$nextTick(() => {
              //console.log('ssssssssss 1', this.store_ModalNegociacao.dados.preco_total, this.store_ModalNegociacao.dados)
              this.store_ModalNegociacao.dialog = true;
            });
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    },

    async MapaByEmpreendimento(cod_empreendimento) {
      const resposta = await API.get(`site/mapa_json`, {
        params: {
          cod_empreendimento: cod_empreendimento,
        },
      });
      if (resposta) {
        var array_properties = []
        resposta.data.result.features.map((el) => {
          el.properties.quadra_ordem = el.properties.quadra.padStart(10, 0)
          array_properties.push(el.properties)
        })
        this.dados = array_properties
        // console.log("this.dados mapa by empreendimento", this.dados)
      }
    },

    async atualizaPoligonos(data) {
      var _this = this;
      if (data)
        _this.geo_JSON = data;
        _this.geo_JSON_Filtro = await new GeoJSON().readFeatures(_this.geo_JSON, {
        featureProjection: "EPSG:3857",
      });
      
      // console.log("11111111111111111111 ");

      this.Filtra_Unidades(_this.geo_JSON_Filtro);
    },

    async Filtra_Unidades(p_GEO) {
// console.log('222222222222222222222222');
      var lo_UnidadesAux = [];
      await p_GEO.forEach((element) => {
        lo_UnidadesAux.push({
          cod_imovel: element.values_.cod_imovel,
          cor: element.values_.cor,
          lote: element.values_.lote,
          metragem: element.values_.metragem,
          quadra: element.values_.quadra,
          quadra_ordem: element.values_.quadra.padStart(10, 0),
          situacao_sistema: element.values_.situacao_sistema,
          valor: element.values_.valor,
          
        });

        return lo_UnidadesAux;
      })

      var array_p_geo = []
      lo_UnidadesAux.map(el => {
        array_p_geo.push(el.values_)
      })
       
      this.$nextTick(() => {
        this.dados = lo_UnidadesAux
      })
      this.$nextTick(() => {
        this.itensFiltro
      })
    },

    all() {
      console.log('dados', this.dados, Array.from(Array(this.dados.length).keys()));

      this.panel = Array.from(Array(this.dados.length).keys())
    },

    none() {
      this.panel = []
    },
  }
};
</script>

<style lang="scss" scoped>

#EmpreendimentoEspelhoVenda {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  width: 100%;
}

@media(max-width: 599px) {
  #EmpreendimentoEspelhoVenda {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#EmpreendimentoEspelhoVenda::-webkit-scrollbar {
  width: 5px;
}

#EmpreendimentoEspelhoVenda::-webkit-scrollbar-button {
  padding: 1px;
}

#EmpreendimentoEspelhoVenda::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#EmpreendimentoEspelhoVenda::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
} 

@media(max-width: 599px) {
  #EmpreendimentoEspelhoVenda::-webkit-scrollbar {
    width: initial;
  }

  #EmpreendimentoEspelhoVenda::-webkit-scrollbar-button {
    padding: initial;
  }

  #EmpreendimentoEspelhoVenda::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #EmpreendimentoEspelhoVenda::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.titulo_propostas{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 17px !important;
}

@media(max-width: 599px) {
  .title-page {
    width: 100% !important;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF !important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100%;
}

@media(max-width: 599px) {
  .container {
    max-width: 100% !important;
    margin-top: -4px !important;
  }

  .avatar {
    margin-top: 4px;
  }
}

.card {
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA) !important;
}

.wrapper-top {
  height: 105px !important;
}

.header-text {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
}

@media(max-width: 599px) {
  .switch > .v-label {
    font-size: 8px;
  }
}

.col-top {
  width: 100vw;
}

.cabecalho {
  background-color: var(--COR_BACKGROUND) !important;
}

@media(max-width: 599px) {
  .cabecalho {
    padding-bottom: 10px !important;
  }
}
</style>
